/* eslint-disable prettier/prettier */
// Init
import React from 'react';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SampleImage1 from '../../assets/images/Service1.png';
import SampleImage2 from '../../assets/images/Services-2.png';
import Bullet1 from '../../assets/images/Bullet-1.png';
import Bullet2 from '../../assets/images/Bullet-2.png';
import Bullet3 from '../../assets/images/Bullet-3.png';

// Component
export default function WeOffer() {
  return (
    <div className="weOfferContainer">
      <div className="weOfferHeadingDiv">
        <h3>SERVICES WE OFFER</h3>
        <p>
          Welcome to the forefront of your triumphs! As your steadfast strategic partner, we&apos;re
          primed to catapult you towards unprecedented success in the real world.
        </p>
      </div>
      <div className="weOfferCardDiv">
        <motion.div
          initial={{ y: 80, opacity: 0.5 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="weOfferCard"
        >
          <div className="weOfferCardImageDiv">
            <img src={SampleImage1} alt="service" />
          </div>
          <div className="weOfferCardTextDiv">
            <h3>Hire a Virtual Assistant</h3>
            <div className="weOfferCardBulletDiv">
              <img src={Bullet1} alt="Bullet Icon" />
              <p>Send a query</p>
            </div>
            <div className="weOfferCardBulletDiv">
              <img src={Bullet2} alt="Bullet Icon" />
              <p>Choose your VA</p>
            </div>
            <div className="weOfferCardBulletDiv">
              <img src={Bullet3} alt="Bullet Icon" />
              <Link to="/contactus">
                <p>
                  Get Started <ArrowRightOutlinedIcon fontSize="large" />{' '}
                </p>
              </Link>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ y: 80, opacity: 0.5 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="weOfferCard"
        >
          <div className="weOfferCardImageDiv">
            <img src={SampleImage2} alt="service" />
          </div>
          <div className="weOfferCardTextDiv">
            <h3>Become a Virtual Assistant</h3>
            <div className="weOfferCardBulletDiv">
              <img src={Bullet1} alt="Bullet Icon" />
              <p>Send your application</p>
            </div>
            <div className="weOfferCardBulletDiv">
              <img src={Bullet2} alt="Bullet Icon" />
              <p>Help us train you</p>
            </div>
            <div className="weOfferCardBulletDiv">
              <img src={Bullet3} alt="Bullet Icon" />
              <Link to="/contactus">
                <p>
                  Get Started <ArrowRightOutlinedIcon fontSize="large" />{' '}
                </p>
              </Link>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
