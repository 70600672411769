// Init
import React, { useEffect } from 'react';

// Importing Components
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import ContactForm from '../components/contactus/ContactForm';

// Home Component
export default function ContactUs() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Helmet>
        <title>Contact Us | Get in Touch | Global360</title>
        <meta
          name="description"
          content="Contact Global360 to learn more about our virtual assistance and hiring services. Our team is ready to help your business succeed."
        />
        <link rel="canonical" href="https://global360.co/contactus" />
      </Helmet>
      <Navbar bg />
      <ContactForm />
      <Footer />
    </div>
  );
}
