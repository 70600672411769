// LocalBusinessSchema.js

import React from 'react';

function LocalBusinessSchema() {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: 'Global360',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '160 Front St #2',
      addressLocality: 'Secaucus',
      addressRegion: 'NJ',
      postalCode: '07094',
      addressCountry: 'United States',
    },
    telephone: '+1 551-377-3471',
    image: '../assets/images/global360Logo.svg',
    url: 'https://www.global360.co',
    sameAs: [
      'https://www.facebook.com/profile.php?id=61559618311197',
      'https://www.instagram.com/global__360/',
      'https://twitter.com/Global__360',
      'https://www.linkedin.com/company/global_360/about/',
    ],
    openingHours: 'Mo,Tu,We,Th,Fr 09:00-17:00',
  };

  return <script type="application/ld+json">{JSON.stringify(schema)}</script>;
}

export default LocalBusinessSchema; // Ensure you export the component correctly
