// Init
import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/footerLogo.svg';
import Facebook from '../../assets/images/facebook.svg';
import Instagram from '../../assets/images/instagram.svg';
import Twitter from '../../assets/images/twitter.svg';
import Linkedin from '../../assets/images/linkedin.svg';

// Component
export default function Footer() {
  return (
    <div className="footerContainer">
      <div className="darkEffect" />
      <div className="footerSubContainer">
        <div className="footerUpperDiv">
          <div className="footerLogoDiv">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
            <div className="footerSocialIconsDiv">
              <a
                href="https://www.facebook.com/profile.php?id=61563637943666&mibextid=ZbWKwL"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Facebook} style={{ width: '20px' }} alt="facebook" />
              </a>
              <a
                href="https://www.facebook.com/shaggy.bhaii?mibextid=ZbWKwL"
                target="_blank"
                rel="noreferrer"
                style={{ display: 'none' }}
              >
                <img src={Facebook} style={{ width: '20px' }} alt="facebook" />
              </a>
              <a href="https://www.instagram.com/global__360/" target="_blank" rel="noreferrer">
                <img src={Instagram} alt="instagram" />
              </a>
              <a href="https://twitter.com/Global__360" target="_blank" rel="noreferrer">
                <img src={Twitter} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/global_360/about/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Linkedin} alt="linkedin" />
              </a>
            </div>
          </div>
          <div className="footerRoutesDiv">
            <h3>Resources</h3>
            <Link to="/">Home</Link>
            <Link to="/aboutus">About Us</Link>
            <Link to="/services">Services</Link>
            <Link to="/contactus">Contact us </Link>
          </div>
          <div className="footerInfoDiv">
            <h3>Contact Us</h3>
            <a
              href="https://www.google.com/maps/search/?api=1&query=New+Jersey,+United+States"
              target="_blank"
              rel="noreferrer"
            >
              <LocationOnIcon sx={{ color: '#5CFDF7' }} /> New Jersey, United States{' '}
            </a>
            <a href="https://wa.me/15513773471" target="_blank" rel="noreferrer">
              <CallIcon sx={{ color: '#5CFDF7' }} /> (551) 377-3471
            </a>
            <a href="mailto:info@global360.co" target="_blank" rel="noreferrer">
              <MailIcon sx={{ color: '#5CFDF7' }} /> info@global360.co
            </a>
          </div>
          <div className="footerSocialMobIconsDiv">
            <a
              href="https://www.facebook.com/profile.php?id=61563637943666&mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Facebook} style={{ width: '20px' }} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/global__360/" target="_blank" rel="noreferrer">
              <img src={Instagram} alt="instagram" />
            </a>
            <a href="https://twitter.com/Global__360" target="_blank" rel="noreferrer">
              <img src={Twitter} alt="twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/global_360/about/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Linkedin} alt="linkedin" />
            </a>
          </div>
        </div>
        <div className="footerDivider" />
        <div className="footerCopyrightDiv">
          {' '}
          {new Date().getFullYear()} Global360. All rights reserved. website design & Web
          development by Global Solutions
        </div>
      </div>
    </div>
  );
}
