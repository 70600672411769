/* eslint-disable prettier/prettier */
// Init
import React from 'react';
import { motion } from 'framer-motion';
import Linkedin from '../../assets/images/linkedinBlack.svg';
import Colby from '../../assets/images/colbyProfile.png';
import Zainab from '../../assets/images/zainabProfile.png';
import Img1 from '../../assets/images/Hexa1.png';
import Img2 from '../../assets/images/Hexa2.png';

// Component
export default function AboutUsSection() {
  return (
    <div className="aboutUsSectionContainer">
      <div className="aboutUsSectionHeadingDiv">
        <h2>
          ABOUT <span>US</span>
        </h2>
        <p>
          With a keen eye for talent and a dedication to client satisfaction, we streamline the
          hiring process, empowering businesses to thrive in today&apos;s digital landscape.
        </p>
      </div>
      <div className="aboutUsProfileContainer">
        <div className="aboutUsProfileHeading">
          <div className="animatedHexa">
            <img className="img1" src={Img1} alt="Hexagon 1" />
            <img className="img2" src={Img2} alt="Hexagon 2" />
          </div>
          <h3>
            OUR <span>TEAM</span>
          </h3>
        </div>
        <div className="aboutUsProfileCardContainer">
          <motion.div
            initial={{ scale: 0.7, opacity: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="aboutUsProfileCard"
          >
            <img src={Colby} alt="profile" />
            <div className="aboutUsProfileCardInfo">
              <h3>Colby Iyer</h3>
              <h4>Co-Founder & CEO</h4>
              <a href="https://www.linkedin.com/in/the-colby-iyer" target="_blank" rel="noreferrer">
                <img src={Linkedin} alt="Linkedin" />
              </a>
            </div>
          </motion.div>
          <motion.div
            initial={{ scale: 0.7, opacity: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="aboutUsProfileCard"
          >
            <img src={Zainab} alt="profile" />
            <div className="aboutUsProfileCardInfo">
              <h3>Zainab Fatima</h3>
              <h4>Co-Founder & COO</h4>
              <a
                href="https://www.linkedin.com/in/dr-zainab-fatima"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Linkedin} alt="Linkedin" />
              </a>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="sectionDivider" />
    </div>
  );
}
