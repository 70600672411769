/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prettier/prettier */
// Init
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Component
export default function ContactForm() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });
    console.log('formData', formData);
    console.log('formData phoneNumber', phoneNumber);
    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: name === 'phone' ? phoneNumber : value,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.firstName) {
            toast.error('Enter Your First Name');
            return;
        }
        if (formData.firstName.length < 2) {
            toast.error("First Name can't be less then 2 alphabets");
            return;
        }
        if (!formData.email) {
            toast.error('Enter Your Email');
            return;
        }
        if (!emailRegex.test(formData.email)) {
            toast.error('Enter Valid Email');
            return;
        }
        if (!formData.phone) {
            toast.error('Enter Your Phone Number');
            return;
        }
        if (!formData.message) {
            toast.error('Please Enter a Message');
            return;
        }
        emailjs.sendForm('service_y6hf4zo', 'template_obefv7i', e.target, 'cv7cUdhFwB7Sm7UD9').then(
            () => {
                toast.success('Email Sent');
            },
            (error) => {
                toast.error('Error', error);
            },
        );
    };

    return (
        <div className="contactFormContainer">
            <motion.div
                initial={{ x: -100, opacity: 0.5 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="contactFormTextDiv"
            >
                <h3>Contact us</h3>
                <h2>
                    Let’s get <br />
                    <span>Started!</span>
                </h2>
                <h6>Let’s get Started!</h6>
                <p>
                    Please fill out this form to request additional information and find out how Global360 can
                    help you!
                </p>
                <br />
                <p>For Immediate assistance, Please Contact us at:</p>
                <a href="https://wa.me/15513773471" target="_blank" rel="noreferrer">
                    <h4>+1 (551) 377-3471</h4>
                </a>
            </motion.div>
            <div className="contactFormDiv">
                <form className="contactForm" onSubmit={sendEmail}>
                    <div className="contactFormFieldDiv">
                        <input
                            type="text"
                            placeholder="FIRST NAME"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="contactFormFieldDiv">
                        <input
                            type="text"
                            placeholder="LAST NAME"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="contactFormFieldDiv">
                        <input
                            placeholder="EMAIL"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="contactFormFieldDiv">
                        <PhoneInput
                            country="us"
                            name="phone"
                            value={phoneNumber}
                            inputProps={{
                                name: 'phone',
                                onBlur: handleChange, // Call handleChange onBlur to update formData
                            }}
                            buttonStyle={{
                                background: 'rgba(212, 221, 230, 0.2)',
                                padding: '0 10px',
                                borderRadius: '18px 0 0 18px',
                            }}
                            onChange={handlePhoneNumberChange}
                            inputStyle={{
                                width: '100%',
                                padding: '22px 14px 22px 70px',
                                borderRadius: '8px',
                                height: 'auto',
                                lineHeight: 'normal',
                                border: '1px solid #D0D5DD',
                                background: 'rgba(212, 221, 230, 0.2)',
                            }}
                        />
                    </div>
                    <textarea
                        placeholder="HOW CAN WE HELP?"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                    />
                    <button type="submit">Contact Us Now</button>
                </form>
            </div>
        </div>
    );
}
