// Init
import React, { useEffect } from 'react';

// Importing Components
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import WeOffer from '../components/services/WeOffer';
import ServiceContact from '../components/services/ServiceContact';

// Home Component
export default function Services() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Helmet>
        <title>Our Services | Virtual Assistance & More | Global360</title>
        <meta
          name="description"
          content="Explore our comprehensive virtual assistance services at Global360. From administrative support to specialized tasks, we help businesses thrive."
        />
        <link rel="canonical" href="https://global360.co/services" />
      </Helmet>
      <Navbar bg />
      <WeOffer />
      <ServiceContact />
      <Footer />
    </div>
  );
}
