// Init
import React from 'react';
import { motion } from 'framer-motion';
import Customer from '../../assets/images/customerIcon.svg';
import Served from '../../assets/images/countriesServedIcon.svg';
import OnBoard from '../../assets/images/onBoard.svg';
import Img1 from '../../assets/images/Hexa1.png';
import Img2 from '../../assets/images/Hexa2.png';

// Component
export default function WeBelieve() {
  return (
    <div className="WeBelieveContainer">
      <div className="WeBelieveHeadingDiv">
        <div className="animatedHexa">
          <img className="img1" src={Img1} alt="Hexagon 1" />
          <img className="img2" src={Img2} alt="Hexagon 2" />
        </div>
        <h3>
          What <span> We Believe</span>
        </h3>
      </div>
      <p className="WeBelieveHeadingPara">
        We’re a passionate Recruitment Agency & HR Consulting advisors helping companies to grow and
        transition to next level.
      </p>
      <div className="WeBelieveMissionVisionDiv">
        <motion.div
          initial={{ x: -100, opacity: 0.5 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="WeBelieveMissionDiv"
        >
          <div className="darkEffect" />
          <h3>OUR MISSION</h3>
          <p>
            To make your talent hunt easier and faster and to help exceptional talents introduce
            themselves without being confined by borders.
          </p>
        </motion.div>
        <motion.div
          initial={{ x: 100, opacity: 0.5 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="WeBelieveMissionDiv"
        >
          <div className="darkEffect" />
          <h3>OUR VISION</h3>
          <p>
            To create a virtual world that connects employers to employees with a relationship of
            mutualism.
          </p>
        </motion.div>
      </div>

      <div className="sectionDivider" />
      <div className="WeBelieveStatsDiv">
        <motion.div
          initial={{ scale: 0.8, opacity: 0.5 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="WeBelieveStats"
        >
          <div className="WeBelieveStatsIconDiv">
            <img src={Customer} alt="Customer" />
          </div>
          <h3>50</h3>
          <h2>Happy Customers</h2>
        </motion.div>
        <div className="verticalDivider" />
        <motion.div
          initial={{ scale: 0.8, opacity: 0.5 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="WeBelieveStats"
        >
          <div className="WeBelieveStatsIconDiv">
            <img src={Served} alt="Countries Served" />
          </div>
          <h3>5</h3>
          <h2>Countries Served</h2>
        </motion.div>
        <div className="verticalDivider" />

        <motion.div
          initial={{ scale: 0.8, opacity: 0.5 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="WeBelieveStats"
        >
          <div className="WeBelieveStatsIconDiv">
            <img src={OnBoard} alt="VA on Board" />
          </div>
          <h3>15</h3>
          <h2>VA on Board</h2>
        </motion.div>
      </div>
    </div>
  );
}
