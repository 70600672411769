// Init
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/global360Logo.svg';
import Facebook from '../../assets/images/facebookRound.svg';
import Youtube from '../../assets/images/youtubeRound.svg';
import Instagram from '../../assets/images/instagramRound.svg';
import Twitter from '../../assets/images/twitterRound.svg';
import Linkedin from '../../assets/images/linkedinRound.svg';
import MobMenu from './MobMenu';

// Component
export default function Navbar(props) {
  const { bg } = props;
  const activePage = window.location.pathname;
  return (
    <div
      className="navbarContainer"
      style={{
        position: bg ? 'relative' : 'absolute',
      }}
    >
      <div
        className="navbarUpperDiv"
        style={{
          background: bg ? 'linear-gradient(90deg, #2D5A86 0%, #003163 100%)' : 'transparent',
        }}
      >
        <div className="navbarLogoDiv">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="navbarHamburger">
          <MobMenu />
        </div>
        <div className="navbarSocialDiv">
          <a
            href="https://www.facebook.com/profile.php?id=61563637943666&mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/global__360/" target="_blank" rel="noreferrer">
            <img src={Instagram} alt="instagram" />
          </a>
          <a href="https://twitter.com/Global__360" target="_blank" rel="noreferrer">
            <img src={Twitter} alt="twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/global_360/about/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Linkedin} alt="linkedin" />
          </a>
          <a
            href="https://www.youtube.com/@Global360_va"
            // target="_blank"
            rel="noreferrer"
            style={{ display: 'none' }}
          >
            <img src={Youtube} alt="Youtube" />
          </a>
        </div>
      </div>
      <div className="navbarLowerDiv">
        <Link style={{ fontWeight: activePage === '/' ? '500' : '400' }} to="/">
          Home
        </Link>
        <Link style={{ fontWeight: activePage === '/aboutus' ? '500' : '400' }} to="/aboutus">
          About Us
        </Link>
        <Link style={{ fontWeight: activePage === '/services' ? '500' : '400' }} to="/services">
          Services
        </Link>
        <Link style={{ fontWeight: activePage === '/contactus' ? '500' : '400' }} to="/contactus">
          Contact Us
        </Link>
      </div>
    </div>
  );
}
