/* eslint-disable prettier/prettier */
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/global360Logo.svg';
import Facebook from '../../assets/images/facebookRound.svg';
import Instagram from '../../assets/images/instagramRound.svg';
import Twitter from '../../assets/images/twitterRound.svg';
import Linkedin from '../../assets/images/linkedinRound.svg';

export default function MobMenu() {
  const [open, setOpen] = React.useState(false);
  const activePage = window.location.pathname;
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      width="100vw"
      height="100vh"
      bgcolor="black"
      role="presentation"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        className="mobMunuLogoDiv"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="1% 5%"
      >
        <Link className="mobMunuLogoSubDiv" to="/">
          <img src={Logo} alt="Logo" />
        </Link>
        <CloseOutlinedIcon sx={{ color: '#fff' }} fontSize="large" onClick={toggleDrawer(false)} />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" width="100%" gap="50px">
        <Link
          style={{
            fontSize: '24px',
            color: activePage === '/' ? '#ffffff' : '#8C8FA3',
            fontWeight: '500',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          to="/"
        >
          Home
        </Link>
        <Link
          style={{
            fontSize: '24px',
            color: activePage === '/aboutus' ? '#ffffff' : '#8C8FA3',
            fontWeight: '500',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          to="/aboutus"
        >
          About Us
        </Link>
        <Link
          style={{
            fontSize: '24px',
            color: activePage === '/services' ? '#ffffff' : '#8C8FA3',
            fontWeight: '500',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          to="/services"
        >
          Services
        </Link>
        <Link
          style={{
            fontSize: '24px',
            color: activePage === '/contactus' ? '#ffffff' : '#8C8FA3',
            fontWeight: '500',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          to="/contactus"
        >
          Contact Us
        </Link>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="3%"
        margin="10%"
      >
        <a
          href="https://www.facebook.com/profile.php?id=61563637943666&mibextid=ZbWKwL"
          target="_blank"
          rel="noreferrer"
        >
          <img style={{ width: '40px' }} src={Facebook} alt="facebook" />
        </a>
        <a href="https://www.instagram.com/global__360/" target="_blank" rel="noreferrer">
          <img style={{ width: '40px' }} src={Instagram} alt="instagram" />
        </a>
        <a href="https://twitter.com/Global__360" target="_blank" rel="noreferrer">
          <img style={{ width: '40px' }} src={Twitter} alt="twitter" />
        </a>
        <a
          href="https://www.linkedin.com/company/global_360/about/"
          target="_blank"
          rel="noreferrer"
        >
          <img style={{ width: '40px' }} src={Linkedin} alt="linkedin" />
        </a>
      </Box>
    </Box>
  );

  return (
    <div>
      <MenuOutlinedIcon onClick={toggleDrawer(true)} color="white" fontSize="large" />

      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
