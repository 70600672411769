// Init
import React, { useEffect, Suspense, lazy } from 'react';

// Importing Components
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
// import Navbar from '../components/common/Navbar';
// import Hero from '../components/home/Hero';
// import WhoAreWe from '../components/home/WhoAreWe';
// import TalentOnBoard from '../components/home/TalentOnBoard';
// import VideoSection from '../components/home/VideoSection';
// import HelpSection from '../components/home/HelpSection';
// import Faqs from '../components/home/Faqs';
// import Testimonial from '../components/home/Testimonials';
// import Footer from '../components/common/Footer';

const Navbar = lazy(() => import('../components/common/Navbar'));
const Hero = lazy(() => import('../components/home/Hero'));
const WhoAreWe = lazy(() => import('../components/home/WhoAreWe'));
const TalentOnBoard = lazy(() => import('../components/home/TalentOnBoard'));
const VideoSection = lazy(() => import('../components/home/VideoSection'));
const HelpSection = lazy(() => import('../components/home/HelpSection'));
const Faqs = lazy(() => import('../components/home/Faqs'));
const Testimonial = lazy(() => import('../components/home/Testimonials'));
const Footer = lazy(() => import('../components/common/Footer'));
// Home Component
export default function Home() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div style={{ overflow: 'hidden' }}>
      <Helmet>
        <title>Virtual Assistance & Hiring Services | Global360</title>
        <meta
          name="description"
          content="Global360 offers virtual assistance services for businesses and professionals. Streamline your hiring process with our expert virtual assistants."
        />
        <link rel="canonical" href="https://global360.co/" />
      </Helmet>
      {/* <Navbar /> */}
      {/* <Hero /> */}
      {/* <WhoAreWe /> */}
      {/* <TalentOnBoard />
      <VideoSection />
      <HelpSection />
      <Faqs />
      <Testimonial />
      <Footer /> */}
      <Suspense>
        <Navbar />
      </Suspense>
      <Suspense>
        <Hero />
      </Suspense>
      <Suspense>
        <WhoAreWe />
      </Suspense>
      <Suspense>
        <TalentOnBoard />
      </Suspense>
      <Suspense>
        <VideoSection />
      </Suspense>
      <Suspense>
        <HelpSection />
      </Suspense>
      <Suspense>
        <Faqs />
      </Suspense>
      <Suspense>
        <Testimonial />
      </Suspense>
      <Suspense>
        <Footer />
      </Suspense>
    </div>
  );
}
