/* eslint-disable no-unused-vars */
// Init
import React, { useEffect } from 'react';

// Importing Components
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import AboutUsSection from '../components/aboutUs/AboutUsSection';
import WeBelieve from '../components/aboutUs/WeBelieve';

// Home Component
export default function AboutUs() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Helmet>
        <title>About Us | Expert Virtual Assistants | Global360</title>
        <meta
          name="description"
          content="Learn about Global360, a leader in virtual assistance and hiring services. Our dedicated team ensures client satisfaction and business success."
        />
        <link rel="canonical" href="https://global360.co/aboutus" />
      </Helmet>
      <Navbar bg />
      <AboutUsSection />
      <WeBelieve />
      <Footer />
    </div>
  );
}
