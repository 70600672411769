// Init
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import Robot from '../../assets/images/robot.png';

// Component
export default function ServiceContact() {
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_y6hf4zo', 'template_04l5q2u', e.target, 'cv7cUdhFwB7Sm7UD9').then(
      () => {
        console.log('SUCCESS!');
        toast.success('Email Sent');
      },
      (error) => {
        console.log('FAILED...', error.text);
        toast.error('Error');
      },
    );
  };

  return (
    <div className="serviceContactContainer">
      <div className="serviceContactHeadingDiv">
        <h3>Looking For Something Specific?</h3>
        <p>Share your query with us!</p>
      </div>
      <div className="serviceContactFormContainer">
        <div className="serviceContactFormGif">
          <img src={Robot} alt="Robot" className="robotAnimation" />
        </div>
        <div className="serviceContactFormDiv">
          <form className="serviceContactForm" onSubmit={sendEmail}>
            <div className="serviceContactFieldDiv">
              <p>Name *</p>
              <input
                required
                type="text"
                placeholder="NAME"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="serviceContactFieldDiv">
              <p>Email *</p>
              <input
                required
                type="email"
                placeholder="EMAIL"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="serviceContactFieldDiv">
              <p>Your Query *</p>
              <textarea
                required
                placeholder="HOW CAN WE HELP?"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <div className="serviceContactButtonDiv">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
